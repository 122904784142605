import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import "../styles.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const PredictGraph = ({ data }) => {
    const chartRef = useRef(null); // Chart.js 인스턴스 참조

    if (
        !data ||
        !data.transactionMonths ||
        !data.predictedPrices ||
        !data.monthlyPrices
    ) {
        //
        return <p>예측 데이터를 불러오는 중입니다...</p>;
    }

    // X축 라벨 통일: 거래월 + 미래월 병합
    const combinedMonths = [...data.transactionMonths, ...data.futureMonths];
    // const xAxisLabels = combinedMonths.filter((_, index) => index % 3 === 0); // 3개월 간격 라벨 표시

    // 미래예측 데이터: 거래월 24.10부터 시작
    const futureStartIndex = data.transactionMonths.findIndex(
        (month) => month === data.futureMonths[0] // futureMonths의 첫 번째 값과 일치하는 인덱스 찾기
    );

    // 유효성 검사: futureStartIndex가 -1이면 기본값 설정
    const validFutureStartIndex =
        futureStartIndex !== -1
            ? futureStartIndex
            : data.transactionMonths.length;

    // futureData 생성: future_months와 동기화
    const futureData = new Array(validFutureStartIndex)
        .fill(null)
        .concat(data.futurePredictions);

    const chartData = {
        // labels: [...filteredTransactionMonths, ...filteredFutureMonths], // 전체 X축
        labels: combinedMonths,
        datasets: [
            {
                label: "실거래가",
                data: data.monthlyPrices.slice(-data.transactionMonths.length), // 월별 평균 거래금액
                borderColor: "#4caf50",
                backgroundColor: "#ffffff",
                fill: true,
            },
            {
                label: "예측가격",
                data: data.predictedPrices, // 예측 금액
                borderColor: "#fbba23",
                backgroundColor: "#ffffff", //#fbba23
                fill: true,
            },
            {
                label: "미래예측",
                data: futureData, // 미래 예측 금액
                borderColor: "#4281ff",
                backgroundColor: "#ffffff",
                fill: true,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                // display: true,
                // position: "top", // 범례를 상단에 배치
                // align: "start", // 범례를 좌측 정렬
                // labels: {
                //     boxWidth: 15, // 범례 사각형 크기
                //     boxHeight: 15,
                //     font: {
                //         size: 14, // 폰트 크기
                //     },
                // },
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const value = context.raw;
                        return `${value.toLocaleString()}억`; // 툴팁 금액 표시
                    },
                },
            },
        },

        scales: {
            x: {
                ticks: {
                    stepSize: 3, // 3개월 간격으로 눈금 표시
                    maxRotation: 0, // X축 레이블 수평
                    callback: function (value, index) {
                        return index % 2 === 0 ? combinedMonths[index] : "";
                    },
                },
            },
            y: {
                ticks: {
                    stepSize: 0.5, // Y축 간격 0.5억으로 설정
                    callback: function (value) {
                        return `${value.toLocaleString()}억`; // Y축 금액 표시
                    },
                },
            },
        },
    };

    // 범례 클릭 이벤트
    const onLegendClick = (index) => {
        const chart = chartRef.current; // Chart.js 인스턴스
        if (chart) {
            const meta = chart.getDatasetMeta(index); // 데이터셋 메타정보
            // meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null; // 표시/숨김 토글
            meta.hidden = !meta.hidden; // 표시/숨김 토글
            chart.update(); // 그래프 업데이트
        }
    };

    const renderLegend = (onLegendClick) => {
        return chartData.datasets.map((dataset, index) => (
            <div
                key={index}
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                    cursor: "pointer", // 클릭 가능한 마우스 커서
                }}
                onClick={() => onLegendClick(index)} // 클릭 이벤트
            >
                <div
                    style={{
                        width: "15px",
                        height: "15px",
                        alignItems: "center" /* 세로 정렬 */,
                        backgroundColor: dataset.borderColor,
                        marginRight: "10px",
                        borderRadius: "10%", // 색상 박스 라운드 처리
                    }}
                ></div>
                <span style={{ fontSize: "14px", color: "#333" }}>
                    {dataset.label}
                </span>{" "}
                {/* 글씨 크기 지정 */}
            </div>
        ));
    };

    return (
        <div>
            <h2 className="panel_title">딥러닝을 활용한 시계열 분석</h2>
            <div className="chart-lstm">
                <div className="chart-legend">
                    {renderLegend(onLegendClick)} {/* 범례 렌더링 */}
                </div>
                <div
                    className="chart-container"
                    style={{ width: "100%", height: "200px" }}
                >
                    <Line data={chartData} options={chartOptions} />
                </div>
            </div>
        </div>
    );
};

export default PredictGraph;
