import React, { useState, useEffect } from "react";
import "../styles.css"; // CSS 파일 임포트
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import {
    MapContainer,
    TileLayer,
    GeoJSON,
    Marker,
    Popup,
    LayersControl,
    useMap,
    useMapEvents
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster"; // 클러스터 컴포넌트 임포트
import axios from "axios";
import L from "leaflet";

const MapView = ({
    selectedMode,
    currentPosition,
    setCurrentPosition,    
    setSelectedComplexCode,
}) => {
    const [boundaryData, setBoundaryData] = useState(null);
    const [apartments, setApartments] = useState([]);
    const [academies, setAcademies] = useState([]);

    const MapEventsHandler = ({ setCurrentPosition }) => {
        const map = useMapEvents({
            dragend() {
                const newCenter = map.getCenter(); // 지도 중심 가져오기
                setCurrentPosition([newCenter.lat, newCenter.lng]); // 상태 업데이트
                console.log("Updated Position: ", [newCenter.lat, newCenter.lng]);
            },
        });
        return null;
    };
    

    // 단지 클릭 이벤트
    const handleComplexClick = (complexCode) => {
        setSelectedComplexCode(complexCode); // 단지코드 업데이트
        console.log(`Complex code clicked: ${complexCode}`); // 디버그 로그 추가
        window.postMessage({ type: "complexCode", code: complexCode }, "*");
    };    
    
    const MapUpdater = ({ currentPosition }) => {
        const map = useMap();
        useEffect(() => {
            if (currentPosition) {
                map.panTo(currentPosition, { animate: true, duration: 1.0 });
            }
        }, [currentPosition, map]);
        return null;
    };

    // 데이터 로드
    useEffect(() => {
        const springBaseUrl = process.env.REACT_APP_SPRING_BASE_URL;
        axios
            .get(`${springBaseUrl}/api/maps/geojson`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                withCredentials: true, // 자격 증명 허용
            })
            .then((res) => setBoundaryData(res.data))
            .catch((err) =>
                console.error("경계 데이터를 가져오는 데 실패했습니다.", err)
            );

        axios
            .get(
                `${springBaseUrl}/api/maps/json/sejongcity_apartment_complex_marker_legend.json`,
                {
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                }
            )
            .then((res) => {
                const data = res.data;

                // complexCode를 key로 사용하는 JSON 데이터를 배열로 변환
                const apartmentsArray = Object.keys(data).map((key) => ({
                    complexCode: key,
                    ...data[key], // 키를 제외한 나머지 속성을 병합
                }));

                setApartments(apartmentsArray);
                console.log("Formatted Apartments Data:", apartmentsArray); // 변환된 데이터 확인
            })
            .catch((err) =>
                console.error("아파트 데이터를 가져오는 데 실패했습니다.", err)
            );

        axios
            .get(`${springBaseUrl}/api/maps/json/sejong_academies.json`, {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            })
            .then((res) => setAcademies(res.data))
            .catch((err) =>
                console.error("학원 데이터를 가져오는 데 실패했습니다.", err)
            );
    }, []);

    if (!boundaryData) return <p>Loading map...</p>;

    // 마커 아이콘 생성 함수
    const createCustomIcon = (apartment) => {
        const {
            complexCode,
            name,
            area,
            meanPrice,
            numberHouseholds,
            approvalDate,
            floorArea,
            buildingCoverage,
        } = apartment;

        // selectedMode에 따라 표시할 정보 변경
        const displayValue =
            selectedMode.title === "실거래가"
                ? `${(parseFloat(meanPrice) / 100000000).toFixed(1)}억`
                : selectedMode.title === "평당가격"
                ? meanPrice === 0 || parseFloat(area) === 0
                    ? "0원" // meanPrice 또는 area가 0인 경우
                    : `${(
                          parseFloat(meanPrice) /
                          parseFloat(area) /
                          10000
                      ).toFixed(1)}만원`
                : selectedMode.title === "세대수"
                ? `${numberHouseholds}세대`
                : selectedMode.title === "입주시기"
                ? approvalDate
                : selectedMode.title === "용적률"
                ? `${floorArea}%`
                : selectedMode.title === "건폐율"
                ? `${buildingCoverage}%`
                : null;

        return L.divIcon({
            className: "custom-pin",
            html: `
                <div id="apt${complexCode}" class="custom-marker" style="cursor: pointer;">
                    <div class="top">${name}</div>
                    <div class="bottom">
                        <span class="info">${displayValue}</span>                       
                    </div>
                </div>
            `,
            iconSize: [80, 50],
            iconAnchor: [40, 50],
        });
    };

    const academyIcon = new L.Icon({
        iconUrl: "/academy_icon.png", // public 폴더에 저장된 아이콘
        iconSize: [25, 25],
    });

    // Cluster Options
    const clusterOptions = {
        iconCreateFunction: (cluster) => {
            const count = cluster.getChildCount();
            let color = count > 50 ? "red" : count > 20 ? "orange" : "green";

            return new L.DivIcon({
                html: `
                    <div style="
                        background-color: ${color};
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                        font-size: 14px;
                        font-weight: bold;
                        box-shadow: 0 2px 6px rgba(0,0,0,0.3);
                    ">
                        ${count}
                    </div>`,
                className: "custom-cluster-icon",
            });
        },
    };

    return (
        <div style={styles.map}>            
            <MapContainer
                center={currentPosition}
                zoom={14}
                style={{ height: "100%", width: "100%", minHeight: "400px" }}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MapEventsHandler setCurrentPosition={setCurrentPosition} />
                <LayersControl position="topright">
                    {/* GeoJSON Layer */}
                    <LayersControl.Overlay name="행정동 경계" checked>
                        <GeoJSON
                            data={boundaryData}
                            onEachFeature={(feature, layer) => {
                                const initialColor = "transparent";
                                const hoverColor = "orange";

                                layer.setStyle({
                                    weight: 1,
                                    color: initialColor,
                                    fillOpacity: 0.2,
                                });

                                layer.bindTooltip(feature.properties.EMD_NM, {
                                    permanent: false,
                                    direction: "auto",
                                    className: "custom-tooltip",
                                });

                                layer.on("mouseover", (e) => {
                                    layer.openTooltip();
                                    layer.setStyle({
                                        weight: 1,
                                        color: hoverColor,
                                        fillOpacity: 0.1,
                                    });
                                });

                                layer.on("mouseout", (e) => {
                                    layer.closeTooltip();
                                    layer.setStyle({
                                        weight: 1,
                                        color: "transparent",
                                        fillOpacity: 0.1,
                                    });
                                });
                            }}
                        />
                    </LayersControl.Overlay>

                    {/* Apartments Layer */}
                    <LayersControl.Overlay name="아파트 단지" checked>
                        <MarkerClusterGroup options={clusterOptions}>
                            {apartments.map((apartment, index) => (
                                <Marker
                                    key={apartment.complexCode}
                                    position={[
                                        apartment.latitude,
                                        apartment.longitude,
                                    ]}
                                    icon={createCustomIcon(apartment)}
                                    eventHandlers={{
                                        click: () =>
                                            handleComplexClick(
                                                apartment.complexCode
                                            ),
                                    }}
                                >
                                    <Popup>{apartment.name}</Popup>
                                </Marker>
                            ))}
                        </MarkerClusterGroup>
                    </LayersControl.Overlay>
                    {/* Academies Layer */}
                    <LayersControl.Overlay name="학원">
                        <MarkerClusterGroup options={clusterOptions}>
                            {academies.map((academy, index) => (
                                <Marker
                                    key={index}
                                    position={[
                                        academy["latitude"],
                                        academy["longitude"],
                                    ]}
                                    icon={academyIcon}
                                >
                                    <Popup>{academy["academy_name"]}</Popup>
                                </Marker>
                            ))}
                        </MarkerClusterGroup>
                    </LayersControl.Overlay>
                </LayersControl>
                <MapUpdater currentPosition={currentPosition} />
                {/* 기타 지도 관련 요소 */}                
            </MapContainer>
        </div>
    );
};

const styles = {
    map: {
        width: "100%",
        height: "100vh", // 화면 높이에서 헤더 높이를 뺌
        position: "fixed",
    },
};

export default MapView;
