// SidePanel.js
import React, { useEffect, useState } from "react";
import "./SidePanel.css";
import PredictGraph from "./PredictGraph"; // PredictGraph 컴포넌트 import
import axios from "axios";

const SidePanel = ({ complexCode, onClose }) => {
    const [details, setDetails] = useState(null);
    const [predictions, setPredictions] = useState(null); // 예측 데이터를 저장할 상태 추가
    const [loading, setLoading] = useState(false); // 로딩 상태 추가
    const [error, setError] = useState(null); // 에러 상태 추가

    // 단지 코드가 변경될 때마다 서버 요청
    useEffect(() => {
        const springBaseUrl = process.env.REACT_APP_SPRING_BASE_URL; // 환경 변수에서 서버 URL 가져오기
        const flaskBaseUrl = process.env.REACT_APP_FLASK_BASE_URL;

        if (complexCode) {
            setError(null);
            setLoading(true);

            setDetails(null);
            // 기존 예측 데이터 삭제 (초기화)
            setPredictions({
                transactionMonths: [],
                monthlyPrices: [],
                predictedPrices: [],
                futureMonths: [],
                futurePredictions: [],
            });
            // 데이터 요청
            (async () => {
                try {
                    await fetchComplexDetails(springBaseUrl, complexCode);
                    await fetchPredictions(flaskBaseUrl, complexCode);
                } catch (err) {
                    setError(
                        err.message ||
                            "데이터를 불러오는 중 오류가 발생했습니다."
                    );
                } finally {
                    setLoading(false); // 로딩 상태 비활성화
                }
            })();
        }
    }, [complexCode]);

    // 단지 세부 정보 호출 함수
    const fetchComplexDetails = async (springBaseUrl, code) => {
        try {
            const response = await axios.get(
                `${springBaseUrl}/api/maps/getComplexDetails`,
                {
                    params: { code }, // 쿼리 파라미터 전달
                }
            );
            setDetails(response.data);
        } catch (error) {
            console.error(
                "Error fetching details:",
                error.response || error.message
            );
        }
    };

    // 예측 데이터 호출 함수
    const fetchPredictions = async (flaskBaseUrl, code) => {
        try {
            const response = await axios.post(
                `${flaskBaseUrl}/api/predict`,
                {
                    apartment_code: code,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true, // 인증 정보 포함 여부
                }
            );
            console.log("서버 응답 데이터:", response.data); // 서버 데이터 출력
            // 거래월과 금액 데이터를 상태에 저장
            setPredictions({
                transactionMonths: response.data.transaction_months,
                monthlyPrices: response.data.monthly_prices, // 월별 평균 거래금액 추가
                predictedPrices: response.data.predictions,
                futureMonths: response.data.future_months,
                futurePredictions: response.data.future_predictions,
            });
        } catch (error) {
            console.error(
                "Error fetching predictions:",
                error.response || error.message
            );
        }
    };

    if (!complexCode) {
        return null; // 단지 코드가 없으면 아무것도 렌더링하지 않음
    }

    return (
        <div className="detail_panel">
            <button className="close_button" onClick={onClose}>
                닫기
            </button>
            {details ? (
                <div>
                    <h2 className="panel_title">{details.단지명}</h2>
                    <table className="info_table">
                        <tbody>
                            <tr className="info_table_item">
                                <th className="table_th" scope="row">
                                    세대수
                                </th>
                                <td className="table_td">{details.세대수}</td>
                                <th className="table_th" scope="row">
                                    최고층
                                </th>
                                <td className="table_td">
                                    {details.최고층수}층
                                </td>
                            </tr>
                            <tr className="info_table_item">
                                <th className="table_th" scope="row">
                                    사용승인일
                                </th>
                                <td className="table_td">
                                    {details.사용승인일}
                                </td>
                                <th className="table_th" scope="row">
                                    총주차대수
                                </th>
                                {
                                    <td className="table_td">
                                        {details.총주차대수}대{" "}
                                    </td> /*(세대당 {details.총주차대수}/{details.세대수}대) */
                                }
                            </tr>
                            <tr className="info_table_item">
                                <th className="table_th" scope="row">
                                    용적률
                                </th>
                                <td className="table_td">{details.용적률}%</td>
                                <th className="table_th" scope="row">
                                    건폐율
                                </th>
                                <td className="table_td">{details.건폐율}%</td>
                            </tr>
                            <tr className="info_table_item">
                                <th className="table_th" scope="row">
                                    건설사
                                </th>
                                <td className="table_td">{details.시공사}</td>
                                <th className="table_th" scope="row">
                                    난방
                                </th>
                                <td className="table_td">{details.난방방식}</td>
                            </tr>
                            <tr className="info_table_item">
                                <th className="table_th" scope="row">
                                    관리사무소
                                </th>
                                <td className="table_td" colSpan="3">
                                    {details.관리사무소연락처}
                                </td>
                            </tr>
                            <tr className="info_table_item">
                                <th className="table_th" scope="row">
                                    주소
                                </th>
                                <td className="table_td" colSpan="3">
                                    {details.법정동주소}
                                </td>
                            </tr>
                            <tr className="info_table_item">
                                <th className="table_th" scope="row">
                                    면적
                                </th>
                                <td className="table_td" colSpan="3">
                                    {details.면적}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>로딩 중...</p>
            )}
            {/* PredictGraph 추가 */}
            <div className="predict_graph_section"  style={{ position: "relative" }}>
                {/* 그래프 위에 메시지 표시 */}
                {(loading || error) && (
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 10,
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            padding: "10px 20px",
                            borderRadius: "8px",
                            textAlign: "center",
                        }}
                    >
                        {loading && <p>데이터를 불러오는 중입니다...</p>}
                        {error && <p style={{ color: "red" }}>{error}</p>}
                    </div>
                )}
                {/* PredictGraph 렌더링 */}
                <PredictGraph data={predictions} /> {/* 상태 전달 */}
            </div>
        </div>
    );
};

export default SidePanel;
