import React, { useState } from "react";
import "./Menu.css";
import myLocationIcon from "../assets/my-location.png"; // 내위치 버튼 이미지 경로
import profile_none from "../assets/profile_none.png"; //로그인 아이콘

const Menu = ({ selectedMode, setSelectedMode, onMoveToCurrentLocation }) => {
    const [isApartModeVisible, setApartModeVisible] = useState(false);

    // 활성화할 메뉴
    const activeTitles = [
        "실거래가",
        "평당가격",
        "세대수",
        "입주시기",
        "용적률",
        "건폐율",
    ];

    const handleButtonClick = (action) => {
        switch (action) {
            case "단지":
                setApartModeVisible(!isApartModeVisible);
                break;
            case "지도":
                console.log("지도 기능 실행");
                break;
            case "필터":
                console.log("필터 기능 실행");
                break;
            // 추가 기능
            default:
                console.log(`${action} 버튼 클릭됨`);
        }
    };
    const modes = [
        {
            id: 9,
            title: "실거래가",
            desc: "최근 3개월 간 실거래된 매매가 평균값",
        },
        { id: 4, title: "매물가격", desc: "온라인 매매 매물가 평균값" },
        { id: 3, title: "평당가격", desc: "온라인 매매 매물, 평균 평당가격" },
        { id: 5, title: "매매전세갭", desc: "매매-전세 가격차이" },
        { id: 6, title: "전세가율", desc: "매매매물 대비 전세 비율" },
        { id: 7, title: "거래회전율", desc: "최근1년 매매 건수/총세대수" },
        { id: 1, title: "세대수", desc: "단지 총 세대수" },
        { id: 2, title: "입주시기", desc: "단지 입주 시기" },
        { id: 8, title: "대지지분", desc: "세대별 대지지분 평균" },
        { id: 10, title: "용적률", desc: "대지면적 대비 건물 총 연면적 비율" },
        { id: 11, title: "대지/용적률", desc: "대지지분 및 용적률 동시 노출" },
        { id: 12, title: "건폐율", desc: "대지면적 대비 건축면적 비율" },
    ];

    // 모드 선택 처리
    const handleModeSelection = (item) => {
        setSelectedMode(item); // 모드 업데이트
        setApartModeVisible(false); // 모드 창 닫기
    };

    return (
        <div className="menu-container">
            {/* 빈프로파일 버튼 */}
            <button
                className="menu-button"
                onClick={() => onMoveToCurrentLocation()}
            >
                <img src={profile_none} alt="프로파일" className="button-icon" />
            </button>
            {/* 위치 이동 버튼 */}
            <button
                className="menu-button"
                onClick={() => onMoveToCurrentLocation()}
            >
                <img src={myLocationIcon} alt="위치" className="button-icon" />
            </button>

            {/* 기타 버튼 */}
            {["지도", "필터", "주변", "거리"].map((label) => (
                <button
                    key={label}
                    className="menu-button"
                    onClick={() => handleButtonClick(label)}
                >
                    {label}
                </button>
            ))}

            {/* 현재 선택된 모드 버튼 */}
            <button
                style={{
                    backgroundColor: "#4169E1", // 배경색
                    color: "white", // 텍스트 색상
                }}
                className="menu-button"
                onClick={() => setApartModeVisible(!isApartModeVisible)}
            >
                {selectedMode.title || "모드 선택"}
            </button>

            {/* 아파트 모드 동적 div */}
            {isApartModeVisible && (
                <div className="mode-wrap">
                    <div className="mode-head">
                        <p className="tit">
                            <span id="title">{selectedMode.title}</span>
                        </p>
                        <p className="txt">
                            <span id="desc">{selectedMode.desc}</span>
                        </p>
                    </div>
                    <div className="mode-cont">
                        <div className="mode-list">
                            {modes.map((item) => {
                                const isActive = activeTitles.includes(
                                    item.title
                                );
                                return (
                                    <button
                                        key={item.id}
                                        className="mode-button"
                                        onClick={() => {
                                            if (isActive) {
                                                handleModeSelection(item);
                                            }
                                        }}
                                        style={{
                                            opacity: isActive ? 1 : 0.5, // 비활성화된 버튼의 투명도 낮춤
                                            cursor: isActive
                                                ? "pointer"
                                                : "not-allowed", // 비활성화 시 마우스 커서 변경
                                            pointerEvents: isActive
                                                ? "auto"
                                                : "none", // 클릭 방지
                                        }}
                                    >
                                        {item.title}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Menu;
