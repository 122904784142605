import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS 임포트
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JavaScript
import { Navbar, Nav, Container } from 'react-bootstrap';
import "../styles.css"; // CSS 파일 임포트

const NavigationBar = () => {
  return (
    <Navbar className="custom-navbar">
      <Container>
        <Navbar.Brand href="#home" className="bold-brand">집플래너</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
