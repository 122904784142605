import React, { useState } from "react";
// import Header from "./components/Header";
import SidePanel from "./components/SidePanel1";
import Menu from "./components/Menu";
import MapView from "./components/MapView";
import NavigationBar from "./components/NavigationBar";
import "./styles.css"; // CSS 파일 임포트

const App = () => {
    // 상태 정의 및 초기값 설정
    const [selectedMode, setSelectedMode] = useState({
        id: 9,
        title: "실거래가",
        desc: "최근 3개월 간 실거래된 매매가 평균값",
    });

    // 지도 위치 상태 추가
    const [currentPosition, setCurrentPosition] = useState([36.48, 127.2897]); // 기본 좌표

    // 선택된 단지코드 상태 추가
    const [selectedComplexCode, setSelectedComplexCode] = useState(null);

    const handleClosePanel = () => {
        setSelectedComplexCode(null); // 단지 코드 초기화
    };

    // 현재 위치 이동 요청 처리
    const handleMoveToCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    const { latitude, longitude } = pos.coords;
                    setCurrentPosition([latitude, longitude]); // 현재 위치 업데이트
                },
                (err) => {
                    console.error("위치 정보를 가져올 수 없습니다.", err);
                }
            );
        } else {
            alert("브라우저가 Geolocation을 지원하지 않습니다.");
        }
    };

    return (
        <div>
            {/* <Header /> */}
            <NavigationBar />
            <main style={styles.main}>
                {/* <Sidebar /> */}
                <div style={styles.content}>
                    {/* Map에 선택된 모드 및 지도 위치 전달 */}
                    <MapView
                        selectedMode={selectedMode}
                        currentPosition={currentPosition}
                        setCurrentPosition={setCurrentPosition} // 위치 이동 가능하도록 수정
                        setSelectedComplexCode={setSelectedComplexCode} // 단지코드 업데이트
                    />
                    {/* 단지코드를 SidePanel에 전달 */}
                    <SidePanel
                        complexCode={selectedComplexCode}
                        onClose={handleClosePanel}
                    />
                    {/* Menu에 상태와 상태 업데이트 함수, 지도 위치 전달 */}
                    <Menu
                        selectedMode={selectedMode}
                        setSelectedMode={setSelectedMode}
                        currentPosition={currentPosition}
                        onMoveToCurrentLocation={handleMoveToCurrentLocation}
                    />

                    {/* <InfoPanel /> */}
                </div>
            </main>
        </div>
    );
};

const styles = {
    main: {
        display: "flex",
        height: "calc(100vh - 50px)",
    },
    content: {
        flex: 1,
        position: "relative",
    },
};

export default App;
